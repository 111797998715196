<template>
    <div class="container">
        <v-row class="">
            <v-col cols="12" class="top-panel px-0 py-0 ml-4">
                <div class="arrow-btn">
                    <img src="../assets/img/arrowBack.svg" class="arrow-img" alt="arrow-back" />
                    <p class="nav-title">Аналитика</p>
                </div>
            </v-col>
        </v-row>
        <div class="tabs">
            <div class="">
                <v-toolbar flat>
                    <div class="toolbar-container">
                        <div>
                            <v-toolbar-title class="title">Выручка по каналам продажа
                            </v-toolbar-title>
                        </div>
                        <div class="row d-flex justify-end">
                            <div class="col-4">
                                <div class="search-component">
                                    <v-autocomplete v-model="selectedRestaurant" :items="restaurants" item-text="name"
                                        item-value="id" outlined label="Выберите ресторан" hide-details solo clearable
                                        placeholder="Выберите ресторан" class="custom-scroll"
                                        @change="getDashboardInformation">
                                    </v-autocomplete>
                                </div>

                            </div>
                            <div class="date-picker">
                                <v-text-field v-model="formattedDateRange"
                                    :label="`Период сравнения: ${formattedDateRange}`" readonly outlined
                                    @click="dialog = true" сlass="custom-text-field" hide-details="auto">
                                    <!-- Иконка календаря слева -->
                                    <template v-slot:prepend-inner>
                                        <v-icon сlass="mt-0">mdi-calendar</v-icon>
                                    </template>
                                </v-text-field>

                                <!-- Диалог с календарем -->
                                <v-dialog v-model="dialog" max-width="290">
                                    <v-card id="date-picker-body">
                                        <v-card-actions class="d-flex justify-center">
                                            <v-btn :class="['rounded-pill', 'mx-2']"
                                                :color="dateType === 'month' ? 'primary' : 'grey lighten-2'"
                                                @click="setDateType('month')" height="40" width="120" elevation="2">
                                                Месячное
                                            </v-btn>
                                            <v-btn :class="['rounded-pill', 'mx-2']"
                                                :color="dateType === 'date' ? 'primary' : 'grey lighten-2'"
                                                @click="setDateType('date')" height="40" width="120" elevation="2">
                                                Недельное
                                            </v-btn>
                                        </v-card-actions>
                                        <v-date-picker v-model="selectedDate" @input="onDateChange" scrollable
                                            :first-day-of-week="1" :allowed-dates="allowedDates" :type="dateType">
                                        </v-date-picker>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <button class="btn-exel" @click="getCompareDashboard()">
                                                Применить
                                            </button>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </div>
                            <div class="btn-container">
                                <!-- <button class="btn-exel" @click="toggleDateType">
                                    {{
                                    dateType === "month"
                                        ? "Недельное сравнение"
                                        : "Месячное сравнение"
                                }}
                                </button> -->
                                <button class="btn-exel">
                                    Выгрузить <img src="../assets/img/exel-btn.svg" alt="" />
                                </button>
                            </div>
                        </div>
                    </div>
                </v-toolbar>
            </div>
        </div>
        <div v-if="noDataMessage" class="no-data-message">
            <div class="error-container d-flex align-center justify-center">
                <div class="chart-heading">
                    <p class="error-head">{{ noDataMessage }}</p>
                    <p class="error-heading">За выбранный период <b>{{ this.formattedDateRange }}</b> отсуствуют данные
                        для
                        сравнения.</p>
                    <p class="error-heading">Попробуйте выбрать другой период или сменить ресторан.</p>
                </div>
            </div>
        </div>
        <div v-else-if="isLoading" class="loading-message d-flex align-center justify-center">
            <v-progress-circular
              indeterminate
              size="40"
              color="primary"
            ></v-progress-circular>
        </div>
        <div v-else>
            <div class="first-container">
                <TableChart :dashboardData="dashboardInfo" />
                <div class="doughnut-border">
                    <h2 class="doughnut-name">Выручка</h2>
                    <DoughnutChart :dashboardData="dashboardInfo" />
                </div>
            </div>
            <div class="chart-container">
                <div class="chart-heading">
                    <p class="chart-head">Выручка по каналам продаж</p>
                    <p class="date-heading">за период {{ formattedDateRange }}</p>
                </div>
                <div>
                    <BarChart :dashboardData="dashboardInfo" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BarChart from "../components/charts/BarChart.vue";
import DoughnutChart from "../components/charts/DoughnutChart.vue"
import moment from "moment";
import TableChart from "../components/charts/TableChart.vue";

export default {
    name: "Analytics",
    components: {
        BarChart,
        DoughnutChart,
        TableChart
    },

    data() {
        return {

            // filteredAccounts: '',
            selectedRestaurant: null, // Хранит выбранный ресторан
            restaurants: [], // Список ресторанов
            dashboardInfo: {},
            noDataMessage: "",
            isLoading: true,
            dialog: false, // Скрываем календарь по умолчанию
            selectedDate: moment(new Date()).format('YYYY-MM-DD'), // Текущая выбранная дата
            dateType: "date", // Выбор по умолчанию: месяц
            formattedDateRange: "",
            startDate: "",
            endDate: "", // Диапазон, отображаемый пользователю // Для отображения диапазона дат
        };
    },
    created() {
        this.setWeeklyDateRange();
        this.getDashboardInformation();
        this.getAllRestaurants();
        this.restaurants.push(this.selectedRestaurant)
        console.log(this.restaurants);

        // this.setInitialDateRange();
    },
    mounted() {
        this.getDashboardInformation();
        this.setInitialDateRange();
        // При загрузке страницы устанавливаем текущий месяц как диапазон
    },
    methods: {
        // getRestaurant(id) {
        //     this.$http
        //         .get(`api/Restaurant/${id}`).then((response) => this.selectedRestaurant = response.data).catch((error) => console.log(error))
        // },
        getAllRestaurants() {
            this.$http
                .get('api/Restaurant').then((response) => {
                    let allRest = { id: null, name: "Все рестораны" }
                    this.restaurants = response.data
                    this.restaurants.unshift(allRest)
                }).catch((error) => console.log(error))

        },
        getCompareDashboard() {
            this.dialog = false;
            this.getDashboardInformation();
        },
        setWeeklyDateRange() {
            // Устанавливаем диапазон для текущей недели
            const today = moment(); // Текущая дата
            this.startDate = today.startOf('month').format("YYYY-MM-DD");
            this.endDate = today.endOf('month').format("YYYY-MM-DD");
            console.log(this.startDate);
            console.log(this.endDate)
            //   this.startDate = this.startDate.toISOString();
            //   this.endDate = this.endDate.toISOString();
        },
        getDashboardInformation() {
            this.isLoading = true;
            this.$http.get('/api/Dashboard/GetRestaurantRevenue', {
                params: {
                    restaurantId: this.selectedRestaurant,
                    startDate: this.startDate, // Укажите нужные даты или динамически
                    endDate: this.endDate
                }
            })
                .then((response) => {
                    if (response.status === 204 || !response.data || !response.data.totalChannelRevenues.length) {
                        this.noDataMessage = 'За выбранный период данных нет.';
                        this.dashboardInfo = {};
                    } else {
                        this.dashboardInfo = response.data;
                        this.noDataMessage = ''; 
                        this.isLoading = false;// Очистить сообщение, если данные есть
                    }
                })
                // Сохраните ответ в вашем data или обработайте его
                .catch((error) =>
                    (console.error('Error fetching dashboard information:', error))
                );
        },
        setInitialDateRange() {
            if (this.dateType === 'month') {
                // Устанавливаем диапазон для текущего месяца
                const startDate = moment(this.selectedDate)
                    .startOf("month")
                    .format("DD.MM.YYYY");
                const endDate = moment(this.selectedDate)
                    .endOf("month")
                    .format("DD.MM.YYYY");
                this.formattedDateRange = `${startDate} - ${endDate}`;
                this.startDate = moment(this.selectedDate).startOf('month').format("YYYY-MM-DD");
                this.endDate = moment(this.selectedDate).endOf('month').format("YYYY-MM-DD");
            } else if (this.dateType === 'date') {
                if (!moment(this.selectedDate, 'YYYY-MM-DD', true).isValid()) {
                    this.selectedDate = moment(new Date()).format('YYYY-MM-DD');
                }
                const startOfWeek = moment(this.selectedDate)
                    .startOf("isoWeek")
                    .format("DD.MM.YYYY");
                const endOfWeek = moment(this.selectedDate)
                    .endOf("isoWeek")
                    .format("DD.MM.YYYY");
                this.formattedDateRange = `${startOfWeek} - ${endOfWeek}`;
            }
            // this.getDashboardInformation();
        },
        setDateType(dateType) {
            this.dateType = this.dateType === 'month' ? 'date' : 'month';
            // Устанавливаем выбранную дату в зависимости от типа
            if (this.dateType === 'month') {
                // Если переключаемся на месяц, используем текущий месяц
                this.selectedDate = moment(new Date()).format('YYYY-MM-DD');
            } else {
                // Для недели используем текущую дату
                this.selectedDate = moment(new Date()).format('YYYY-MM-DD');
            }
            // Устанавливаем диапазон дат
            this.setInitialDateRange();
        },
        toggleDateType() {

            // Переключаем тип сравнения (месяц/неделя)
            this.dateType = this.dateType === 'month' ? 'date' : 'month';

            // Устанавливаем выбранную дату в зависимости от типа
            if (this.dateType === 'month') {
                // Если переключаемся на месяц, используем текущий месяц
                this.selectedDate = moment(new Date()).format('YYYY-MM-DD');
            } else {
                // Для недели используем текущую дату
                this.selectedDate = moment(new Date()).format('YYYY-MM-DD');
            }

            // Устанавливаем диапазон дат
            this.setInitialDateRange();

            // Оставляем диалог открытым только если он был открыт до этого
        },
        onDateChange(date) {
            if (this.dateType === "month") {
                // Если выбран месяц, устанавливаем диапазон на месяц
                const startDate = moment(date).startOf("month").format("DD.MM.YYYY");
                const endDate = moment(date).endOf("month").format("DD.MM.YYYY");
                this.startDate = moment(this.selectedDate).startOf('month').format("YYYY-MM-DD");
                this.endDate = moment(this.selectedDate).endOf('month').format("YYYY-MM-DD")
                this.formattedDateRange = `${startDate} - ${endDate}`;
            } else if (this.dateType === "date") {
                // Если выбрана неделя, вычисляем начало и конец недели
                const startOfWeek = moment(date).startOf("isoWeek").format("DD.MM.YYYY");
                const endOfWeek = moment(date).endOf("isoWeek").format("DD.MM.YYYY");
                this.startDate = moment(this.selectedDate)
                    .startOf("isoWeek")
                    .format("YYYY-MM-DD");
                this.endDate = moment(this.selectedDate)
                    .endOf("isoWeek")
                    .format("YYYY-MM-DD");
                this.formattedDateRange = `${startOfWeek} - ${endOfWeek}`;
            }
        },
        allowedDates(date) {
            // Разрешаем выбирать только недели или месяцы
            if (this.dateType === "month") {
                return true;
            } else if (this.dateType === "date") {
                return moment(date).day() === 1; // Позволяем выбирать недели (по понедельникам)
            }
        }
    },
};
</script>

<style scoped>
.doughnut-name {
    font-family: Inter;
    font-weight: 700;
    font-size: 15px;
    color: #323c4d;
    margin-bottom: 19px;
}

.row {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
}

.table-border {
    background-color: white;
}

.doughnut-border {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 677px;
    width: 100%;
}

.chart-head {
    font-family: Inter;
    font-size: 1.0416vw;
    font-weight: 700;
    line-height: 24.2px;
    text-align: left;
}

.date-heading {
    font-family: Inter;
    font-size: 0.833vw;
    font-weight: 400;
    line-height: 19.36px;
    text-align: left;
}


.chart-container {
    /* max-width: 1645px; */
    /* Задаем максимальную ширину контейнера */


    margin: 20px 0px;
    padding: 20px;
    background: #ffffff;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.error-container {
    max-width: 50%;
    /* Задаем максимальную ширину контейнера */
    margin: 0 auto;
    margin-top: 20px;
    /* margin: 0px 20px; */
    padding: 20px;
    background: #ffffff;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 10px;
}

.error-head {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
    text-align: center;
}

.error-heading {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: center;
}

.tabs {
    padding: 0 30px 0px 0px;
}

.toolbar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

::v-deep .v-text-field.v-text-field--solo .v-input__control {
    min-height: 36px;
    padding: 0;
}

.btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btn-exel {
    border-radius: 5px;
    padding: 5px 18px;
    color: white;
    background: #323c4d;
    height: 36px;
    font-family: Inter;
    font-weight: 400;
    font-size: 0.78125vw;
    display: flex;
    align-items: center;

}

::v-deep .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 100%;
    border-bottom: 0.5px solid rgb(212, 212, 225);
}

::v-deep .v-autocomplete__content .v-list-item__title {
    color: black;
    /* Цвет текста */
}

::v-deep .v-autocomplete__content.v-menu__content {
    color: #000;
    background-color: #000;
    background: #000;
}

::v-deep .v-select__slot {
    border: none;
}

::v-deep .v-text-field--outlined.v-input--is-focused fieldset {
    border: none;
}

::v-deep .v-autocomplete.v-input--outlined .v-input__control {
    border: none !important;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none !important;
    border: 1px solid rgb(224, 224, 224);
}

::v-deep .v-text-field--outlined fieldset {
    border: none !important;
}

::v-deep .v-text-field--outlined>.v-input__control>.v-input__slot {
    align-items: stretch;
    min-height: 36px;
    box-shadow: none;
    border: 1px solid rgb(224, 224, 224);
    border-radius: 5px;
}

/* ::v-deep .v-text-field--outlined>.v-input__control>.v-input__slot {
    align-items: stretch;
    min-height: 36px;
    box-shadow: none;
    border: 1px solid rgb(224, 224, 224); 
    border-radius: 5px;
} */

::v-deep .theme--light.v-sheet {
    background-color: transparent;
    border-color: #ffffff;
    color: rgba(0, 0, 0, 0.87);
}

.arrow-img {
    cursor: pointer;
    margin-left: 16px;
}

.nav-title {
    font-family: Inter;
    font-weight: 400;
    font-size: 0.8vw;
    color: #98a2b2;
    margin-bottom: 0px;
    margin-left: 11px;
}

.arrow-btn {
    /* border-right: 1px solid var(--04, #eaeaea); */
    /* width: 56px;
    height: 100%; */
    display: flex;
    align-items: center;
}

.img-pluss-btn {
    margin-left: 10px;
}

.top-panel {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.row-1:last-child {
    background-color: rgb(50, 60, 77);
    border-radius: 6px;
}

.price-white {
    font-family: Inter;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0px;
    color: white;
}

.column-title-white {
    font-family: Inter;
    font-size: 13px;
    color: white;
    margin-bottom: 0px;
}

.four-container-title {
    color: white;
    font-size: 16px;
    font-family: Inter;
    font-weight: bold;
    margin-bottom: 0px;
}

.third-container-title {
    color: #14bf91;
    font-size: 16px;
    font-family: Inter;
    font-weight: bold;
    margin-bottom: 0px;
}

.second-container-title {
    color: #006d5a;
    font-size: 16px;
    font-family: Inter;
    font-weight: bold;
    margin-bottom: 0px;
}

.col-four {
    width: 221px;
    max-width: 221px;
}

.col-third {
    width: 186px;
    max-width: 186px;
}

.col-second {
    width: 186px;
    max-width: 186px;
}

.col-first {
    width: 114px;
    max-width: 114px;
}

.container-padding-right {
    padding-right: 7px;
}

.container-padding-left {
    padding-left: 18px;
}

.shadow-box-container {
    border-radius: 8px;
}

.stats-container {
    display: flex;
    justify-content: space-between;
    border-left: none;
    border-right: none;
}

/* Добавляем бордеры только первому элементу */
.row-1 .stats-container:first-of-type {
    border-left: 1px solid #d4d4d4;
    /* border-right: 1px solid grey; */
}

/* Убираем бордеры у последнего элемента */
.row-1 .stats-container:last-of-type {
    /* border-left: none; */
    border-right: none;
}

.price-column {
    display: flex;
    flex-direction: column-reverse;
}

.percent-text-gray {
    color: #c1c1c1;
    font-family: Inter;
    font-weight: 400;
    font-size: 13px;
}

.percent-text-red {
    color: #e41313;
    font-family: Inter;
    font-weight: 400;
    font-size: 11px;
}

.price {
    font-family: Inter;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0px;
    color: rgb(50, 60, 77);
}

.column-title {
    font-family: Inter;
    font-size: 13px;
    color: rgb(132, 132, 132);
    margin-bottom: 0px;
}

.first-container-title {
    color: #13414e;
    font-size: 16px;
    font-family: Inter;
    font-weight: bold;
    margin-bottom: 0px;
}

.first-container {
    /* width: max-content; */
    display: flex;
    /* margin: 36px auto 0;
    max-width: 1645px; */

    gap: 22px;
}

.row-1 {
    align-items: center;
    display: flex;
    max-width: 1055px;
    padding: 9px 16px;
    margin-bottom: 10px;
    border-radius: 6px;
}

.title {
    font-family: Inter;
    font-weight: 700;
    font-size: 1.25vw !important;
    color: rgb(50, 60, 77);
    margin-right: 10px;
}

.date-picker {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 12px;
}

.btn-container :last-child {
    margin-left: 10px;
}

.custom-text-field {
    background-color: white;
    margin-bottom: 100px;
    /* Белый фон */
}

/* Центрирование иконки */
.calendar-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

::v-deep .v-input__prepend-inner {
    margin-top: 8px !important;
}

::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot {
    background: #fff;

}

.custom-text-field ::v-deep .v-label {
    transform: translateY(-10px);
    /* Смещение текста вверх */
    font-size: 29px;
    /* Размер шрифта */
    color: #6b6b6b;
    /* Цвет текста */
}

::v-deep .v-text-field .v-label--active {
    max-width: 1000%;
    top: 5px !important;
    left: -60px !important;
    font-size: 0.9vw;
    font-family: Inter;
    font-weight: 400;
    line-height: 15.73px;
    color: #98a2b2 !important;
}

#date-picker-body {
    background: #ffffff;
}

@media (min-width: 1264px) {
    .container {
        max-width: 100% !important;
        /* background-color: #E4E4E4; */
        /* border-radius: 8px; */
        margin-left: 16px;
        margin-right: 30px;
        padding: 0px !important;
    }
}

@media (min-width: 960px) {
    .container {
        max-width: 100% !important;
        margin-left: 16px;
        margin-right: 30px;
        padding: 0px !important;
    }
}
</style>
