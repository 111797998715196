<template>
  <Bar class="custom-class" :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId"
    :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses" :styles="styles" :width="width"
    :height="height" />
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
export default {
  components: {
    Bar
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 1000
    },
    height: {
      type: Number,
      default: 455
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => []
    },
    plugins: {
      type: Array,
      default: () => []
    },
    dashboardData: {
      type: Object,  // Мы ожидаем объект
      default: () => ({})
    },
  },
  data() {
    return {
      // chartData: {
      //   labels: [
      //     "1.09",
      //     "2.09",
      //     "3.09",
      //     "4.09",
      //     "5.09",
      //     "6.09",
      //     "7.09",
      //     "1.09",
      //     "2.09",
      //     "3.09",
      //     "4.09",
      //     "5.09",
      //     "6.09",
      //     "1.09",
      //     "2.09",
      //     "3.09",
      //     "4.09",
      //     "5.09",
      //     "6.09"
      //   ],
      //   datasets: [
      //     {
      //       label: 'В зале',
      //       backgroundColor: '#13414E',
      //       data: [25000, 28000, 15000, 55000, 37000, 40000, 20000, 25000, 28000, 15000, 55000, 37000, 40000, 25000, 28000, 15000, 55000, 37000, 40000]
      //     },
      //     {
      //       label: 'Доставка',
      //       backgroundColor: '#006D5A',
      //       data: [65000, 18000, 45000, 25000, 37000, 60000, 57000, 25000, 28000, 15000, 55000, 37000, 40000, 25000, 28000, 15000, 55000, 37000, 40000]
      //     },
      //     {
      //       label: 'На вынос',
      //       backgroundColor: '#14BF91',
      //       data: [15000, 48000, 35000, 75000, 47000, 20000, 17000, 25000, 28000, 15000, 55000, 37000, 40000, 25000, 28000, 15000, 55000, 37000, 4000]
      //     }
      //   ]
      // },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: false
          },
          legend: {
            labels: {
              font: {
                family: 'Inter', // Шрифт для легенды
                size: 15, // Размер шрифта для легенды
              },
              color: '#323c4d' // Цвет шрифта для легенды
            }
          },
          tooltip: {
            titleFont: {
              family: 'Inter', // Шрифт для заголовка подсказок
              size: 14, // Размер шрифта заголовка подсказок
            },
            titleColor: '#FFFFFF', // Цвет шрифта заголовка подсказок
            bodyFont: {
              family: 'Inter', // Шрифт для текста подсказок
              size: 14, // Размер шрифта текста подсказок
            },
            bodyColor: '#FFFFFF', // Цвет шрифта текста подсказок
          }
        },
        scales: {
          x: {
            stacked: true,
            categoryPercentage: 0.3, // Процент от ширины доступной категории (0.0 до 1.0)
            barThickness: 100,
            ticks: {
              font: {
                family: 'Inter', // Шрифт для оси X
                size: 12, // Размер шрифта для оси X
              },
              color: '#000000' // Цвет шрифта для оси X
            }
          },
          y: {
            stacked: true,
            ticks: {
              font: {
                family: 'Inter', // Шрифт для оси Y
                size: 12, // Размер шрифта для оси Y
              },
              color: '#000000' // Цвет шрифта для оси Y
            }
          }
        }
      }
    }
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      // Форматируем дату в нужный формат (например, "дд.мм")
      return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}`;
    }
  },
  computed: {
    chartData() {
      // const { dailyRevenues } = this.dashboardData;
      const dailyRevenues = this.dashboardData.dailyRevenues || [];

      // Получаем метки и данные для каждого дня
      const labels = dailyRevenues.map(item => this.formatDate(item.date));
      const inHouseData = dailyRevenues.map(item => item.inHouseRevenue);
      const deliveryData = dailyRevenues.map(item => item.deliveryRevenue);
      const selfPickUpData = dailyRevenues.map(item => item.selfPickUpRevenue);

      return {
        labels: labels,

        datasets: [
          {
            label: 'В зале',
            backgroundColor: '#13414E',
            data: inHouseData,


          },
          {
            label: 'Доставка',
            backgroundColor: '#006D5A',
            data: deliveryData,
            font: 'Inter'
          },
          {
            label: 'На вынос',
            backgroundColor: '#14BF91',
            data: selfPickUpData,
            font: 'Inter'
          }
        ]
      };
    }
  }
}
</script>


<style scoped>
.custom-class {
  width: 100% !important;
  height: 100% !important;
}
</style>